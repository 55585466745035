import RecordingWrap from "pages/recording/recordingwrap"
import BlinkscribeHome from "../pages/landing/BlinkScribeHome"

import LockPage from "pages/auth/screens/lock"
import UsernamePasswordLogin from "pages/auth/screens/logincred"
import OtpLoginScreen from "pages/auth/screens/login"
import BlinkScribeSignup from "pages/auth/screens/signup"
import Auth from "pages/auth/screens/auth"
// import Getotp from "pages/auth/screens/getotp"
import ForgotPassword from "pages/auth/screens/forgotpassword"
import Archive from "pages/recording/Archive"
import Editscreen from "pages/recording/Editscreen"
import Verify from "pages/auth/screens/Verify"
import UpdatePassword from "pages/auth/screens/updatepassword"
import ManageProf from "pages/profile/manageprof"
import Test from "test"

export const routes=[{
    path:'/landing',
    component:BlinkscribeHome,
    showHeader:true
},{
    path:'/record/:id/:patientid',
    component:RecordingWrap,
    showHeader:true
},
{
    path:'/archive/:id',
    component:Archive,
    showHeader:true
},
{
    path:'/archive-edit/:id/:name',
    component:Editscreen,
    showHeader:true
},
{
    path:'/manage-profile',
    component:ManageProf,
    showHeader:true
},{
    path:'/auth',
    component:Auth,
    showHeader:false
},{
    path:'/test',
    component:Test,
    showHeader:false
},
{
    path:'/auth1',
    component:OtpLoginScreen,
    showHeader:false   
},
{
    path:'/login',
    component:UsernamePasswordLogin,
    showHeader:false
},
{
    path:'/verify',
    component:Verify,
    showHeader:false
},
{
    path:'/forgotpassword',
    component:ForgotPassword,
    showHeader:false
},
{
    path:'/updatepassword',
    component:UpdatePassword,
    showHeader:false
},
{
    path:'/sign-up',
    component:BlinkScribeSignup,
    showHeader:false
}
,{
    path:'/lock',
    component:LockPage,
    showHeader:false
}

]