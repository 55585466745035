import { useState, useEffect, useRef } from "react";
import HomeIcon from '../assets/svgs/homeicon';
import logo from '../assets/blinkscribe.png';
import ProfileIcon from '../assets/svgs/profileicon';
import { useNavigate } from "react-router-dom";
import { resetData, resetSettings, setProviders, setVisittype } from "../redux/store";
import { useDispatch } from "react-redux";

const MainHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch= useDispatch()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleProfileClick = () => {
        setIsMenuOpen(!isMenuOpen);
        navigate('/manage-profile');
    };

    // Close the menu if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close menu
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className="relative flex flex-row justify-between items-center w-full p-2 sm:px-10">
            <div className="flex items-end gap-3" onClick={()=>navigate('/landing')}>
                <img src={logo} alt="BlinkScribe Logo" className="h-8 sm:h-10 cursor-pointer" />
                <div className="font-extralight text-black text-sm sm:text-sm tracking-[0] leading-normal">
                    PRO
                </div>
            </div>
            <div className="flex items-end gap-3 sm:gap-5 relative">
            <div style={{cursor:'pointer'}} onClick={()=>{
              navigate('/landing')
              // window.location.reload();
            }}>
                <HomeIcon  className="h-6 sm:h-8 cursor-pointer"  />
            </div>
                <div className="relative">
                    <div style={{cursor:'pointer'}} onClick={toggleMenu}>
                        <ProfileIcon className="h-6 sm:h-8 cursor-pointer" />
                    </div>

                    {isMenuOpen && (
  <div
    ref={menuRef}
    className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-10 w-36 max-w-auto"
  >
    <ul className="py-2">
      <li
        onClick={handleProfileClick}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        My Profile
      </li>
      <li
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          navigate('/lock');
        }}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        Lock
      </li>
      <li
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          dispatch(resetSettings())
          dispatch(setProviders([]))
          dispatch(setVisittype([]))
          dispatch(resetData());
          localStorage.removeItem('mytab')
          localStorage.removeItem('auth');
          navigate('/auth');
        }}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        Logout
      </li>
    </ul>
  </div>
)}

                </div>
            </div>
        </div>
    );
};

export default MainHeader;
