import React, { useState, useEffect } from "react";
import ScribeFilter from "./scribefilter";
import ScribeTable from "./scribetable";
import { formatCurrentDate } from "utils/date";
import api from "api/api";
import { set } from "date-fns";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSavedload,setProviders,resetSettings,setSelectedTab, setVisittype } from "../../redux/store";
import AuthOverlayCard from "./overlayauth/authoverlay";
import { parseISO, startOfDay } from 'date-fns'; // Import from date-fns
import { toZonedTime } from 'date-fns-tz'; // Only if you need this for timezone
import TermsOverlayCard from "./overlayauth/termsoverlay";
import ToastMessage from "utils/muialerts/righttoptoast";
const BlinkscribeHome = () => {
  const [isAllpatients, setIsAllpatients] = useState(true);
  const selectedTab = useSelector((state) => state.dashboardTab.selectedTab);
  const [tab, setTab] = useState("ALL");
  const [filteredData, setFilteredData] = useState([]);
  const [offset, setOffset] = useState(0);
  const department_id = localStorage.getItem("dp_id");
  const mytabs=localStorage.getItem('mytab')
  const[bypassLoader,setBypassLoader]=useState(false)
  const[isToSyc,setIsToSync]=useState(false)
  const[stoprefresh,setStopRefresh]=useState(false)
  const[isfirstload,setIsfirstLoad]=useState(true)
  const[isinsertion,setisinsertion]=useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false); 
  const [currentTimestamp, setCurrentTimeStamp] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const [records, setRecords] = useState([]);
  const [datefilter, setDateFilter] = useState("2024-07-14");
  const [istableloading, setIstableLoading] = useState(false);
  const[getuserLoading,setGetUserLoading]=useState(false)
  const [ehrLoading,setEhrLoading]=useState(false)
  const[newappointements,setNewAppointments]=useState(0)
  let showcount=false
  const dispatch = useDispatch();
  const [isverified,setIsVerified]=useState(0)
  const savedload = useSelector((state) => state.data.savedload);
  const provid = useSelector((state) => state.settings.providers);
  const vis=useSelector((state)=> state.settings.visittype)
  const [alertmessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  let payload = {
    filter_array: {
      date_filter: {
        start_date: currentTimestamp.startDate,
        end_date: currentTimestamp.endDate,
        start_time: currentTimestamp.startTime,
        end_time: currentTimestamp.endTime,
      },
      providername:provid,
      purpose:vis
    },

    dash_filter:mytabs||"ALL",
  //  limit: 200,
    //"offset": 0,
  };
  const fetchuser=async ()=>{
    try {
      setGetUserLoading(true)
      const response = await api.get('/auth/me')
      if(response?.bm_user){
        if(response?.disclaimer=='PENDING'){
setIsVerified(2)
        }else{
          setIsVerified(0)
        }
      }else{
        setIsVerified(1)
      }
    
    } catch (error) {
      
    }finally{
   setGetUserLoading(false) 
    }
    }
  

  
  useEffect(()=>{
fetchuser()
  },[])

  const fetchDashBoardData = async () => {
    let obj=JSON.parse(JSON.stringify(payload))
       if(obj.filter_array?.providername&&!obj.filter_array?.providername.length){
         delete obj.filter_array?.providername
       }
       if(obj.filter_array?.purpose&&!obj.filter_array?.purpose.length){
        delete obj.filter_array?.purpose
       }   
      
    dispatch(setSavedload({ ...obj }));
    try {

      setIstableLoading(true);
      const response = await api.post(`/v1/appointments/dashboard `, {
        ...obj,
        offset: offset,

      });
      if (response.status === 200) {
        // if (showcount) {
        //     // Extract existing appointment IDs from records
        //     const existingAppointmentIds = new Set(records.map(appointment => appointment.id));
    
        //     // Filter new appointments that are not in the existing records
        //     const newAppointments = response.data.filter(appointment => !existingAppointmentIds.has(appointment.id));
    
        //     // Count of new appointments
        //     const newAppointmentsCount = newAppointments.length;
    
        //     // Call setNewAppointments if there are new appointments
        //     if (newAppointmentsCount > 0) {
        //       setAlertMessage(`${newAppointmentsCount} New Appointments Found`);
        //       setSeverity(severity);
        //       setOpen(true);
        //     }else{
        //        setAlertMessage(`0 New Appointments Found`);
        //     setSeverity(severity);
        //     setOpen(true);
        //     }
        //     showcount=false
        // }
        setIsToSync(false)
        // Update records with the new data
        setRecords(response.data || []);
    }
     else if (response.status == 201) {
      setIsToSync(true)
        setRecords([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIstableLoading(false);
      setIsfirstLoad(false)   
           return 
         }
  };
useEffect(()=>{
setTab(mytabs)
},[tab])
  useEffect(() => {
      setFilteredData(records);
  }, [records]);
const handleVerified=(x)=>{
setIsVerified(x)
}

  const handletimeStamp = (stamp) => {
    setCurrentTimeStamp(stamp);
  };
  const stampformater = async () => {
    const today = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
    const startTime = "07:00:00.000Z"; // Initial start time
    const endTime = "19:00:00.000Z"; // Initial end time

    const dateFilter = savedload?.filter_array?.date_filter;

    if (
      !dateFilter ||
      !dateFilter.start_date ||
      !dateFilter.end_date ||
      !dateFilter.start_time ||
      !dateFilter.end_time
    ) {
     
      setCurrentTimeStamp({
        startDate: today,
        endDate: today,
        startTime: startTime,
        endTime: endTime,
      });
    } else {
      setCurrentTimeStamp({
        startDate: dateFilter.start_date,
        endDate: dateFilter.end_date,
        startTime: dateFilter.start_time,
        endTime: dateFilter.end_time,
      });
    }
  };

  useEffect(() => {
    stampformater();
  }, []);
  useEffect(() => {
    payload = savedload;  
  }, []);
  const handleallreduxclose=()=>{
    
  // payload.filter_array.date_filter={
  //   start_date: currentTimestamp.startDate,
  //   end_date: currentTimestamp.endDate,
  //   start_time: currentTimestamp.startTime,
  //   end_time: currentTimestamp.endTime,
  // }

  dispatch(setProviders([]))
  dispatch(setVisittype([]))
  fetchDashBoardData()
  }
 
  useEffect(() => {
    if (currentTimestamp.startDate) {      
      fetchDashBoardData();
    }
  
  }, [currentTimestamp, offset]);
  // useEffect(() => {   
  //   const interval = setInterval(() => { 
  //     if(!stoprefresh){      
  //       fetchDashBoardData();
  //     }       
    
  //   }, 5000);
  //  // 30000
  //   return () => clearInterval(interval); // Clear interval on component unmount
  // }, [offset, currentTimestamp]);
  const handleScribeFilter = (patient_id) => {
    if (!patient_id) {
      return;
    }
    payload.search_filter = `${patient_id}`;
    fetchDashBoardData();
    setStopRefresh(true)
  };
  const handleRecordType = (providers, purpose, filterstate) => {
    if (!filteredData.length) {
      return;
    }
    
    if (providers.length) {
      payload.filter_array.providername = providers;        
     //dispatch(resetSettings())
      dispatch(setProviders(providers))
    }
    if (purpose.length) {
      payload.filter_array.purpose = purpose;
      dispatch(setVisittype(purpose))
    }
    if (filterstate) {
      if (purpose.length && !providers.length) {
        delete payload.filter_array.providername;
        dispatch(resetSettings())
        dispatch(setProviders([]))
        dispatch(setVisittype(purpose))
      } else if (!purpose.length && providers.length) {
        delete payload.filter_array.purpose;
        
        dispatch(resetSettings())
        dispatch(setVisittype([]))
        dispatch(setProviders(providers))
      } else if (!purpose.length && !providers.length) {
        delete payload.filter_array.providername;
        delete payload.filter_array.purpose;
        dispatch(resetSettings())
      }
      if (filterstate) {
        fetchDashBoardData();      
        return;
      }
    }
  };
  const handledatefilter = (value) => {
    setDateFilter(value);
  };
  const fetchEhr = async (x) => {
    try {
   
      setEhrLoading(true)
    const response=  await api.post("/v1/ehr/appointments/sync", {
        department_id: department_id,
        "start_date":currentTimestamp.startDate,
         "end_date":currentTimestamp.endDate
      });
      if(response.status==200){
        setAlertMessage(`${response.new_appointments} New Appointments Found`);
        setSeverity(severity);
        setOpen(true);
      }else{
        setAlertMessage(`0 New Appointments Found`);
        setSeverity(severity);
        setOpen(true);
      }
    } catch (error) {
      console.log(error)
    }finally{
  setEhrLoading(false)
showcount=true
await fetchDashBoardData();
//setShowCount(false)
    }

    
  };

  const handleTabs = (x) => {
 
    setTab(x);
    localStorage.setItem('mytab',x)
    payload.dash_filter = x; 
    dispatch(setSelectedTab('PENDING'))
     fetchDashBoardData();
   // payload.dash_filter = "ALL";
  };

  return (
    <>
<div className={`mt-2 ${isDateOpen ? 'lg:h-[105vh] md:h-[110vh] sm:h-[110vh]' : 'h-auto'} flex flex-col gap-8 items-start p-2 px-2 sm:px-20 md:px-20`}>
<div className="flex w-full flex-col gap-3 items-center sm:items-start ">
          <div className="text-base [font-family:'Poppins-Medium',Helvetica] font-normal text-black tracking-[0] leading-[normal] whitespace-nowrap">
            {formatCurrentDate()}
          </div>
          <div className="w-full">
            <ScribeFilter
            setIsToSync={setIsToSync}
              payload={payload.filter_array}
              handleScribeFilter={handleScribeFilter}
              records={records}
              filteredData={filteredData}
              fetchDashBoardData={fetchDashBoardData}
              originaldata={records}
              handleRecordType={handleRecordType}
              fetchEhr={fetchEhr}
              handletimeStamp={handletimeStamp}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center md:justify-start items-center w-full sm:flex-nowrap sm:items-start mt-1 gap-4">
          <button
            onClick={() => handleTabs("ALL")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab == "ALL" ? "bg-[#1c2a3a] text-white" : "bg-white text-black"
            } text-sm  rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            All Patients
          </button>
          <button
            onClick={() => handleTabs("PENDING")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab == "PENDING"
                ? "bg-[#1c2a3a] text-white"
                : "bg-white text-black"
            } text-sm  rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            Pending
          </button>
          <button
            onClick={() => handleTabs("COMPLETED")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab == "COMPLETED"
                ? "bg-[#1c2a3a] text-white"
                : "bg-white text-black"
            } text-sm  rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            Completed
          </button>
        </div>

        <div className="w-full h-auto  ">
          <ScribeTable
            currenttimestamp={currentTimestamp}
            handletimeStamp={handletimeStamp}
            handledatefilter={handledatefilter}
            istableloading={istableloading}
            isfirstload={isfirstload}
            originaldata={records}
            filteredData={filteredData}
            data={filteredData}
            handleRecordType={handleRecordType}          
            fetchDashBoardData={fetchDashBoardData}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            isDateOpen={isDateOpen}
            setIsDateOpen={setIsDateOpen}
            handleallreduxclose={handleallreduxclose}
            isToSyc={isToSyc}
            fetchEhr={fetchEhr}
            datetoday={payload.filter_array.date_filter.start_date}
          />
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={istableloading||getuserLoading||ehrLoading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
      {isverified==1&&<AuthOverlayCard handleVerified={fetchuser} isOpen={true}/>}
      {isverified==2&&<TermsOverlayCard handleVerified={fetchuser} isOpen={true}/>}
    </>
  );
};

export default BlinkscribeHome;
