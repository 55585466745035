import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useParams } from 'react-router-dom';
import MainHeader from './header/header';
import { routes } from './routes/routes'; 
import './App.css';
import PageNotAvailable from 'error/pagenotfound';
import AudioOverlay from 'audiopopup/audiooverlay';
import useModalStore from 'zustand/zustandstore';
import ToastMessage from 'utils/muialerts/righttoptoast';
import { submitmyaudio } from 'audiohandlers/audiosubmissions';
import { Backdrop, CircularProgress } from '@mui/material';
import CombineToastModal from 'alerts/recordingscreen/combineToast';
import { Combinemyaudio } from 'audiohandlers/audiosubmissions';


const App = () => {
  return (
    <div className="App">
      <Router  basename="/">
        <AppContent />
      </Router>
    </div>
  );
};

function AppContent() {
  const accessToken = localStorage.getItem('auth'); 
  const location = useLocation(); 
  const { id, patientid} = useParams(); 
  const currentRoute = routes.find(route => route.path === location.pathname);
  const mydata = useModalStore();
  const {setModalValue}=useModalStore()
  const [open, setOpen] = useState(false);
  const[isloading,setIsloading]=useState(false)
  const [combineaudio,setCombineAudio]=useState(false)
   const [audioFile,setAudiofile]=useState('')
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const unrestrictedRoutes = ["/auth",'/login','/sign-up',"/updatepassword","/forgotpassword",'/record','/auth1','/verify'];  
  if (!unrestrictedRoutes.includes(location.pathname) && !accessToken) {
    return <Navigate to="/auth" />;
  }
  const retrieveAudioBlob = () => {
     const base64String = sessionStorage.getItem('audioBlob');
    if (base64String) {
      // Extract the base64 data from the string
      const byteString = atob(base64String.split(',')[1]);
      
      // Get the MIME type from the base64 string
      const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  
      // Create a Uint8Array to hold the binary data
      const ab = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ab[i] = byteString.charCodeAt(i);
      }
  
      // Create a Blob from the Uint8Array
      const audioBlob = new Blob([ab], { type: mimeString });
  
      console.log('Retrieved audio Blob:', audioBlob);
      return audioBlob;
    } else {
      console.log('No audio Blob found in session storage.');
      return null;
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
const ContinueSubmit = async (combinedAudioBlob, state) => {
  try {
    setIsloading(true);
    let response = null;
    if (state === 'new') {
      response = await submitmyaudio(combinedAudioBlob, mydata);
    } else if (state === 'combine') {
      response = await Combinemyaudio(combinedAudioBlob, mydata);
    }
    if (response && response.status === 200) {
      setMessage('Your Recording is Successfully Completed!');
      setSeverity('info'); 
      setOpen(true);
    } else {
      setMessage('Something Went Wrong');
      setSeverity('warning');
      setOpen(true);
    }

  } catch (error) {
 
    setMessage('Something Went Wrong');
    setSeverity('error');
    setOpen(true);
  } finally {
     setIsloading(false);
    setModalValue({ isCombine: false });
    setCombineAudio(false)
  }
};

const handlecombine= async(toggle)=>{
  if(toggle=='new'){  
    ContinueSubmit(audioFile,'new')
  }else if(toggle=='combine'){   
    ContinueSubmit(audioFile,'combine')
  }

}

const Audiocollecter= async(x)=>{

  // if(mydata.isbacktorecord){
  //   alert('dfsggret4ewr')
  //   return
  // }
  console.log(id,patientid,'sudh7yd')
  
  const mydat = useModalStore.getState();
  if(mydat.isCancel){
    return
  }
const combinedAudioBlob = new Blob([retrieveAudioBlob(), x],{ type: 'audio/webm' });
 if(mydata.isCombine){
setAudiofile(combinedAudioBlob)
setCombineAudio(true)
 }else{
 ContinueSubmit(combinedAudioBlob,'new')
 }
  
      
}
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.path) {
        return <Route exact path={route.path} element={<route.component />} key={route.path} />;
      }
      return null;
    });
  const mergeaudio=(x)=>{
    console.log(x,'xxxxxxx')
    const combinedAudioBlob = new Blob([retrieveAudioBlob(), x],{ type: 'audio/webm' });
    const reader = new FileReader();
      reader.onloadend = () => {
      const base64data = reader.result;
      sessionStorage.setItem('audioBlob', base64data);
    };
    reader.readAsDataURL(combinedAudioBlob);
    console.log(combineaudio,'merged')
  }

//[background:linear-gradient(180deg,rgb(255,255,255)_20%,rgb(162,198,253)_100%)]
  return (
    <div className='bg-white h-auto min-h-[100vh]  [background:linear-gradient(180deg,rgb(255,255,255)_20%,rgb(162,198,253)_100%)]'>
   {mydata.isOpen?<AudioOverlay Audiocollecter={Audiocollecter} mergeaudio={mergeaudio}/>:<></>}
      {currentRoute && currentRoute.showHeader && <MainHeader />}
        <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
              <CombineToastModal isModalOpen={combineaudio} handlecombine={handlecombine} setModalOpen={setCombineAudio}/>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading}>
     <CircularProgress color="inherit" />
   </Backdrop>
      <Routes>
      
        {getRoutes(routes)}

        <Route path="/" element={<Navigate to="/auth" />} />
        
    
        <Route path="*" element={<PageNotAvailable />} />
      </Routes>
    </div>
  );
}

export default App;
