// modalStore.js
import {create} from 'zustand';

const useModalStore = create((set) => ({
  isOpen: false,
  isCancel:false,
  isCombine:false,
  type: '',
  file: [],
  depId: null,
  patId: null,
  patName: '',
  appId: null,
  notes: '',
  practId: null,
  filename: '',
  rId: null,
  tags: [],
  isRunning:true,
  recordtime:"00:00",
  isbacktorecord:false,


  openModal: (payload) => set((state) => ({
    isOpen: true,
    ...payload,
  })),
  
  closeModal: () => set({ isOpen: false }),

  resetModal: () => {
    console.log("resetting")
    set({
    isOpen: false,
    // type: '',
    // file: null,
    // depId: null,
    // patId: null, 
    // appId: null,
    // notes: '',
    // practId: null,
    // rId: null,
    // tags: [],
    // isRunning:true,
    //  recordtime:"00:00"
  })},

  setModalValue: (updates) => set((state) => ({
    ...state,
    ...updates,
  })),
  
}));

export default useModalStore;
