import React, { useEffect, useState, useRef } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import api from '../../api/api';
import { CircularProgress, TextField, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import editIcon from '../../assets/edit.png';

const ProfileDetails = ({ screenhandler }) => {
  const [showdp,setShowdp]=useState(false)
  const navigate = useNavigate();
  const[fetchimg,setFetchimg]=useState(false)
  const [data, setData] = useState({
    username: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(true);
  const [profilePic, setProfilePic] = useState(false);
  const fileInputRef = useRef(null); // Ref for the file input

  const uploadProfile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await api.put('/v1/users/upload-profile-pic', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Assuming the new image URL is in response.data.profile_picture_url
      const newProfilePicUrl = response.data.profile_picture_url;
  
      // Update the profilePic with the new URL and add a timestamp to avoid caching issues
      setProfilePic(`${newProfilePicUrl}?t=${new Date().getTime()}`);
      
      console.log('Profile picture uploaded:', response.data);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    } finally {
      fetchProfile();  // Optionally fetch the profile again to ensure consistency
    }
  };
  
  const fetchProfile = async () => {
    try {
      setFetchimg(true);
      const response = await api.get('/v1/users/profile-pic');
  
      if (response.status === 200) {
        
        setProfilePic(`${response.profile_picture_url}?t=${new Date().getTime()}`);
        setShowdp(true);
      }
    } catch (error) {
      setProfilePic(face); // Fallback to default face image
      setShowdp(false);
      console.error('Error fetching profile picture:', error);
    } finally {
      setFetchimg(false);
    }
  };
  
  const getUser = async () => {
    try {
      const response = await api.get('/auth/me');
      setData({
        username: response.username,
        email: response.email,
        phone: response.mobilephone,
        fullname: response.firstname + " " + response.lastname,
      });
    } catch (error) {
      console.error('Error fetching user data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      
      uploadProfile(file); 
    }
  };
  

  const handleEditClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  useEffect(() => {
    getUser();
    fetchProfile();
  }, []);

  const handleNavigate = (label) => {
    screenhandler(label, data.username, data.phone, data.email);
  };

  return (
    <>
      {loading||fetchimg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <CircularProgress color="inherit" />
        </div>
      )}
      <div className={`w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg`}>
        <div className='flex gap-8'>
          <div className="flex items-start">
            <img
              onClick={() => navigate("/landing")}
              src={arrowleft}
              alt="Back Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
         
          <div className="flex items-center justify-start gap-2">
            {!fetchimg&&(showdp?<div className="relative flex items-center justify-center">
              <img src={profilering} alt="Profile Ring" className="w-[71px] h-[73px]" />
              <img src={profilePic || face} alt="Face" className="absolute w-[63px] h-[63px] left-1 top-1 rounded-full" />
              <IconButton onClick={handleEditClick} className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton>
              {/* Hidden input for selecting file */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>:<div className="relative flex items-center justify-center">
              <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
              <img src={profilePic || face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
              <IconButton onClick={handleEditClick} className="absolute bottom-0 right-6 top-5">
                <img src={editIcon} alt="Edit" className="w-[20px] h-[20px]" />
              </IconButton>
              {/* Hidden input for selecting file */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>)}
            <div className='flex flex-col text-start'>
              <div className="font-semibold text-black text-lg">{data.username}</div>
              <div className="opacity-50 text-black text-sm">{data.email}</div>
            </div>
      
          </div>
        </div>
        <hr className="border-t border-[#D0D0D0] w-full m-4" />
        <div className='flex w-full items-center justify-center'>
          <div className='flex flex-col w-[60%] mb-4 gap-6'>
            {[
              { label: "Full Name", type: "text", placeholder: "", value: data.fullname },
              { label: "Email", type: "email", placeholder: "", value: data.email },
              { label: "Phone Number", type: "tel", placeholder: "Enter phone number", value: data.phone },
              { label: "Current Password", type: "password", placeholder: "********", value: '', onChange: () => {} },
            ].map((inputField, index) => (
              <div key={index} className='flex flex-col w-full items-start gap-3'>
                <label className="opacity-80 text-black">{inputField.label}</label>
                <div className="relative w-full">
                  <TextField
                    type={inputField.type}
                    placeholder={inputField.placeholder}
                    value={inputField.value}
                    onChange={inputField.onChange}
                    variant="outlined"
                    fullWidth
                    disabled
                    className="bg-[#f9f9f9] pr-10" // Padding to the right to make space for the icon
                  />
                  {(inputField.label === "Email" || inputField.label === "Current Password") && (
                    <span 
                      onClick={() => handleNavigate(inputField.label)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <FontAwesomeIcon icon={faEdit} className="text-gray-600 cursor-pointer" />
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
         
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
