import MusicWaves from "./musicwaves";
import redcircle from "../../assets/redcircle.png";
import pause from "../../assets/Pause.png";
import play from "../../assets/play.png";
import { useRef, useState, useEffect, useImperativeHandle } from "react";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteToastModal from "alerts/recordingscreen/deletetoast";
import useModalStore from "zustand/zustandstore";

const RecordingPlayer = ({ setBackScreen, submitAudio, setToastAlert, setToastObject , myref}) => {
  const [isRecording, setIsRecording] = useState(false);
  const{setModalValue }=useModalStore()
  const [isPaused, setIsPaused] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recordingTime, setRecordingTime] = useState("00:00"); 
  const [isDelete, setIsDelete] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
const[persistRecordTime,setPersistRecordTime]=useState('')
const[showsavedtime,setSavedTime]=useState(false)
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const startTimeRef = useRef(null); // For when recording started
  const elapsedTimeRef = useRef(0); // To store total elapsed time
  const animationFrameRef = useRef(null); 
  const retrieveAudioBlob = () => {
    const base64String = sessionStorage.getItem('audioBlob');
   if (base64String) {
     // Extract the base64 data from the string
     const byteString = atob(base64String.split(',')[1]);
     
     // Get the MIME type from the base64 string
     const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
 
     // Create a Uint8Array to hold the binary data
     const ab = new Uint8Array(byteString.length);
     for (let i = 0; i < byteString.length; i++) {
       ab[i] = byteString.charCodeAt(i);
     }
 
     // Create a Blob from the Uint8Array
     const audioBlob = new Blob([ab], { type: mimeString });
 
     console.log('Retrieved audio Blob:', audioBlob);
     return audioBlob;
   } else {
     console.log('No audio Blob found in session storage.');
     return null;
   }
 }


  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setMediaStream(stream); 
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunks.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
      submitAudio(audioBlob); 
      
    const reader = new FileReader();
  reader.onloadend = () => {
    const base64data = reader.result;
    // Store the base64 string in session storage
    sessionStorage.setItem('audioBlob', base64data);
  };
console.log(audioBlob,'start')
  reader.readAsDataURL(audioBlob);
      audioChunks.current = [];
    };

    mediaRecorderRef.current.start();
    startTimeRef.current = Date.now(); // Record start time
    setIsRecording(true);
    setIsPaused(false);

    updateRecordingTime(); // Start timer
  };
useEffect(()=>{
updateRecordingTime()
},[])

  const stopRecording = () => {
        if (!isRecording) return;    
 setModalValue({recordtime:recordingTime}) 
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    setIsPaused(false);
    setBackScreen(false);
    cancelAnimationFrame(animationFrameRef.current); 
    return 
  };

  const pauseRecording = () => {
    if (!mediaRecorderRef.current) return;

    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause();
      
      setIsPaused(true);
      elapsedTimeRef.current += Date.now() - startTimeRef.current;
      setPersistRecordTime(recordingTime)
      setSavedTime(true)
      // Update total elapsed time
      cancelAnimationFrame(animationFrameRef.current); // Stop timer
    }
  };

  const resumeRecording = () => {
    if (!mediaRecorderRef.current) return;

    if (mediaRecorderRef.current.state === "paused") {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimeRef.current = Date.now(); // Reset start time
      setSavedTime(false)
      updateRecordingTime(); // Resume timer
    }
  };

  const updateRecordingTime = () => {
    const now = Date.now();
    const elapsedTime = Math.floor((now - startTimeRef.current + elapsedTimeRef.current) / 1000); // Add elapsed time to current time
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    setRecordingTime(
      `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );

    animationFrameRef.current = requestAnimationFrame(updateRecordingTime); // Continue updating timer
  };

  useEffect(() => {
    if (!isRecording) {
      startRecording();
    }

    return () => {
      cancelAnimationFrame(animationFrameRef.current); // Clean up timer on component unmount
    };
  }, [isRecording]);

  const handleDelete = () => {
    setToastObject({
      icon: CancelIcon,
      text1: "Your Recording is Successfully Cancelled!",
      text2: "",
    });
    setToastAlert(true);
    setBackScreen(false);
  };
  useImperativeHandle(myref, () => ({
    stopRecording,
  }));
  return (
    <>
      <div className="mt-[7%] gap-5 flex flex-col justify-center items-center">
        <div className="h-28 overflow-hidden">
            <MusicWaves  mediaStream={mediaStream} isPaused={isPaused} /> 
        </div>
     
        {isPaused && (
          <div className="flex items-center justify-center w-[50%]">
            <p className="[font-family:'DM_Sans-Regular',Helvetica] font-normal text-black text-sm text-center tracking-[0] leading-4">
              Remember to tap ‘Save’ to keep your recording
            </p>
          </div>
        )}
        <div className="flex flex-col gap-2 items-center justify-center">
          <div className="[font-family:'Poppins-SemiBold',Helvetica] font-semibold text-black text-base text-center tracking-[0] leading-[normal]">
            {!isPaused ? "Recording..." : "Paused..."}
          </div>
          <div className="flex flex-row mt-4 gap-4 justify-between items-center">
            <button
              onClick={() => {
                if (!isPaused) {
                  setIsDelete(true);
                  pauseRecording();
                  setIsDelete(false);
                }
                setDeleteModal(true);
              }}
              className="bg-[#1c2a3ae6] rounded-lg p-2 "
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Delete
              </div>
            </button>
            <div
              className="relative cursor-pointer"
              onClick={() => (isPaused ? resumeRecording() : pauseRecording())}
            >
              <img
                src={redcircle}
                alt="Red Circle"
                className="w-[80px] h-[80px] cursor-pointer"
              />
              <img
                src={!isPaused ? pause : play}
                alt="Pause/Play"
                className="absolute inset-0 m-auto w-[34px] h-[34px]"
              />
            </div>
            <button
              onClick={stopRecording}
              className="bg-[#1c2a3ae6] rounded-lg p-2"
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Save
              </div>
            </button>
          </div>
          <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#1c2a3a] mt-8 text-lg text-center tracking-[0] leading-[30px] whitespace-nowrap">
  {recordingTime.length !== 5 ? "00:00" : (showsavedtime ? (showsavedtime ? persistRecordTime : recordingTime) : recordingTime)}

</div>

        </div>
      </div>
      <DeleteToastModal
        handledelete={handleDelete}
        isModalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />
    </>
  );
};

export default RecordingPlayer;
